@import "../../_vars.scss";

.add-search {
  margin-top: 10px;
  position: relative;

  &__loading {
    height: 92px;

    @media (max-width: $mediaMobile) {
      background-color: rgba(243, 243, 243, 0.5);
      margin: 4px -5px -5px -5px;
      padding: 5px 10px 10px 10px;
      position: absolute;
      max-width: calc(100% - 20px);
      width: 360px;
      height: 448px;
    }
  }

  &__control {
    display: flex;

    &-group {
      display: flex;
      margin-right: 20px;

      @media (max-width: $mediaMobile) {
        margin-right: 15px;
      }

      &--noMargin {
        margin-right: 0;
      }
    }
  }

  &__close {
    display: none;

    @media (max-width: $mediaMobile) {
      display: block;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0px;
      top: $headerMobile;
    }
  }

  &__icon {
    position: relative;
    height: 24px;
    display: inline-block;
    width: 24px;
    cursor: pointer;

    svg {
      fill: $blue;
      background: transparent;
    }

    @media (max-width: $mediaMobile) {
      height: 22px;
      min-width: 22px;
      width: 22px;

      svg {
        fill: $white !important;
        width: 100%;
        height: 100%;
      }
    }

    &--apply {
      top: 3px;

      svg {
        width: 75%;
        height: 77%;
      }
    }

    &--plus {

      svg {
        width: 89%;
        height: 96%;
      }
    }

    &--alt {
      left: -3px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &--rotate {
      transform: rotate(180deg);
    }
  }

  &__button {
    color: $textBlue;
    border: 0;
    background: 0;
    vertical-align: super;
    padding: 0;
    cursor: pointer;

    @media (max-width: $mediaMobile) {
      color: $white;
      align-self: baseline;
      text-align: left;
      margin-top: 2px;
    }

    &--right {
      margin: 0 0 0 auto;
    }
  }

  &__options {
    display: flex;

    @media (max-width: $mediaMobile) {
      overflow-y: auto;
      height: calc(100vh - 150px);
      flex-direction: column;
      background: $bgCompany;
      margin: 5px -5px -5px;
      padding: 10px 10px 25px;
      position: absolute;
      max-width: calc(100% - 20px);
      width: 360px;
      z-index: 1;
    }
  }

  &__option {

    @media (max-width: $mediaMobile) {
      width: 100%;
    }

    &--left {
      padding-right: 10px;
      max-width: 330px;
      width: 100%;

      @media (max-width: $mediaMobile) {
        max-width: 100%;
        padding: 0;
      }

      @media (min-width: $mediaMobile) {
        padding-top: 23px;
      }

      @media (width: $mediaMobile) {
        padding-top: 0;
      }
    }

    &--center {
      padding: 0 10px;
      border-left: 1px solid $black;
      border-right: 1px solid $black;
      max-width: 100px;
      width: 100%;

      @media (max-width: $mediaMobile) {
        max-width: unset;
        border: 0;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        margin: 10px 0;
        padding: 10px 0;
      }

      .add-search__option-group {
        margin-right: 0;
      }
    }

    &--right {
      margin-left: 10px;
      flex-basis: 66%;
      width: 100%;

      @media (max-width: $mediaMobile) {
        margin: 0;
      }
    }

    &--cylinderHeads {
      padding: 0 10px;
      border-left: 1px solid $black;
      border-right: 1px solid $black;
      flex-basis: 40%;
      width: 100%;

      @media (max-width: $mediaMobile) {
        max-width: unset;
        border: 0;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        margin: 10px 0;
        padding: 10px 0;
      }
    }

    &--otherProducts {
      padding-left: 10px;
      max-width: 280px;
      width: 100%;
      flex-basis: 10%;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: $mediaMobile) {
        flex-direction: column;
      }

      &--center {
        @media (max-width: $mediaMobile) {
          flex-direction: column;
        }
      }
    }

    &-group {
      display: flex;
      align-items: center;
      margin: 5px 10px 0 0;

      @media (max-width: $mediaMobile) {
        margin-right: 0;
      }

      &--product {
        min-width: 110px;

        @media (max-width: $mediaMobile ) {
          width: auto;
          align-self: flex-start;
        }
      }

      &--cylinderHead {
        min-width: 95px;

        @media (max-width: $mediaMobile) {
          width: auto;
          align-self: flex-start;
        }
      }

      &--otherProducts {
        min-width: 135px;

        @media (max-width: $mediaMobile) {
          width: auto;
          align-self: flex-start;
        }
      }
    }

    &-label {
      padding-left: 5px;
      white-space: nowrap;

      &--active {
        color: $textGreen;
      }
    }

    &-title {
      margin-bottom: 5px;
      text-align: center;
      font-weight: bold;

      &.required {

        &::after {
          content: '*';
          margin-left: 2px;
        }
      }
    }

    &-icon {
      width: 23px;
      height: 23px;
      position: absolute;
      margin-top: -3px;
      margin-left: 5px;

      svg {
        fill: $black;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__required-text {
    margin-top: 10px;
  }
}