@import "../../_vars.scss";

.footer {
  height: 40px;
  width: 100%;
  padding: 10px 20px;
  background-color: $bg;
  text-align: right;
  position: fixed;
  bottom: 0;
  z-index: 400;

  @media (max-width: $mediaMobile) {
    height: 30px;
    padding: 5px 5px;
    text-align: left;
    font-size: 13px;
    display: flex;
    align-items: center;
  }

  &__logo {
    height: 20px;
    text-align: right;
    display: none;

    @media (max-width: $mediaMobile) {
      display: block;
      margin: 0 0 0 auto;
    }

    img {
      height: 20px;
    }
  }

  &__text {
    color: $textGray;
    margin: 0 5px;
    text-decoration: none;

    @media (max-width: $mediaXS) {
      margin: 0;
    }
  }
}