@import "../../_vars.scss";

.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  width: 100%;
  background-color: $bg;
  display: flex;
  flex-grow: 3;
  min-height: 290px;
  height: 100%;
  padding-bottom: $footerHeight;

  @media (max-width: $mediaMobile) {
    padding-bottom: $footerHeightMobile;
    margin-top: $headerMobile - 1;
    height: calc(100vh - #{$headerFooterMobile}) !important;
  }

  @media (max-width: $mediaXS) {
    height: calc(100vh - #{$headerFooterMobileXS}) !important;
  }
}