@import "../../_vars.scss";

.leaflet-container {
  height: 100%;
  width: 100%;

  .marker-cluster-large,
  .marker-cluster-medium,
  .marker-cluster-small {
    background-color: rgba(62, 96, 157, 0.6);

    div {
      background-color: rgb(62, 96, 157);
      color: $white;
      font-weight: bold;
    }
  }
}

.route-link {
  background: $white;
  width: 25px;
  height: 25px;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
  cursor: pointer;

  @media (max-width: $mediaMobile) {
    border: 1px solid rgba(0,0,0,0.2);
    padding: 6px;
  }

  &:hover {
    background-color: #F4F4F4;
  }

  &__icon {
    height: 18px;
    width: 16px;
    cursor: pointer;
    mask-size: 100%;

    svg {
      width: 100%;
      height: 100%;
      fill: $black;
    }
  }
}
.leaflet-routing-container-hide {

  .leaflet-routing-collapse-btn {
    padding-right: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
}

.leaflet-routing-collapse-btn {
  right: 0px;
  padding-right: 20px;
  cursor: pointer;

  &:hover {
    color: #999999;
  }
}

/* CSS hack for chrome\webkit from https://browserstrangeness.bitbucket.io/css_hacks.html */
_:-webkit-full-screen-ancestor,
:root .leaflet-routing-collapse-btn {
  padding-right: 5px;
}

.leaflet-control-attribution {
  display: none;
}

@media (max-width: $mediaMobile) {
  .leaflet-routing-container-hide {
    width: 34px !important;
    height: 34px !important;
    padding: 0;
  }
}