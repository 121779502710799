// media
$media320: '359px';
$mediaXS: '360px';
$mediaMobile: '768px';
$mediaTablet: '1024px';
$mediaDesktopSmall: '1366px';
$fontFamily: 'Roboto', sans-serif;
// vars
$headerMobile: 140px;
$headerFooterMobile: 170px;
$headerMobileXS: 154px;
$headerFooterMobileXS: 184px;
// basic
$bg: #FFFFFF;
$bgCompany: #F3F3F3;
$bgSecondary: #AFAFAF;
$borderDefault: #838383;
$borderRadiusList: 5px;
$scrollBackground: #C4C4C4;
$btn: #5d5d5d;
// text
$black: #000000;
$white: #FFFFFF;
$textDarkGray: #323131;
$textActiveGray: #BEBEBE;
$textGray: #6C6C6C;
$textLightGray: #919191;
$textBlue: #385F9B;
$textGreen: #027500;
$textRed: #750000;
// colors
$green: #1caf2e;
$red: #e30800;
$blue: #385F9B;
// mixins

@mixin textOverflow {
  overflow: hidden;
  white-space: nowrap;
}

@mixin backgroundCover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin resetBtnStyles {
  -webkit-appearance: none !important;
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
}

@keyframes modalFadeIn {
  from {
    transform: translate(-50%, -60%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

/* hack for ios safari, because on safari the translate animation work with bugs */
@supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
  @keyframes modalFadeIn {
    from {
      // transform: translate(-50%, -60%);
      opacity: 0;
    }
    to {
      // transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
}

@keyframes modalFadeOut {
  from {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -60%);
    opacity: 0;
  }
}

@keyframes modalOverlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalOverlayFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

// others
$boxShadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
$footerHeight: 40px;
$footerHeightMobile: 30px;
