@import "../../_vars.scss";

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  top: -3px;

  @media (max-width: $mediaMobile) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    transform: translateY(calc(-100% - 5px));
  }

  &-toggle {
    display: inline-block;
    height: 37px;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    padding: .375rem .75rem;
    line-height: 1.5;
    border-radius: 10px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #545b62;
    border: none;

    &:hover {
      background-color: #5a6268;
      border-color: #545b62;
    }

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }

    &::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .255em;
      vertical-align: .155em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
    }
  }

  &-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1001;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    color: #212529;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 5px;

    &.show {
      display: block;
    }
  }

  &-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    color: #212529;
    white-space: nowrap;
    background-color: transparent;

    @media (max-width: $mediaMobile) {
      padding: .5rem 1.5rem;
    }

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }

    &:hover,
    &.active:hover {
      color: #16181b;
      background-color: #edf0f2;
    }

    &.active {
      background-color: #f6f7f8;
    }
  }
}
