@import "../../_vars.scss";

.companies {
  &__container {

    &--choosen {
      background-color: $textActiveGray;

      @media (max-width: $mediaMobile) {
        background-color: transparentize($textActiveGray, 0.15);
      }
    }
  }

  &__item {
    display: flex;
    border-bottom: 1px solid $borderDefault;
    font-size: 14px;
    padding: 10px 20px 0 25px;
    cursor: pointer;

    @media (max-width: $mediaMobile) {
      padding: 10px 15px 0 10px;
    }

    &-modal {
      position: absolute;
      display: flex;
      flex-direction: column;
      max-width: 560px;
      width: 100%;
      min-height: 442px;
      border: 1px solid #cccccc;
      background: #ffffff;
      overflow: auto;
      outline: none;
      padding: 20px;
      box-sizing: border-box;
      min-height: 540px;

      @media (max-width: $mediaMobile) {
        max-width: 450px;
      }
    }
  }

  &__left {
    width: calc(100% - 28px);
  }

  &__right {
    width: 28px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__title {
    margin: 5px;
    height: 40px;
    font-size: 18px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /*! autoprefixer: ignore next */
    -webkit-line-clamp: 2;
    /*! autoprefixer: ignore next */
    -moz-box-orient: vertical;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;

    @media (max-width: $mediaMobile) {
      font-size: 15px;
    }
  }

  &__text {
    margin: 5px;
    height: 16px;

    &--open {
      color: $textGreen;
    }

    &--unknown {
      color: $textBlue;
    }

    &--close {
      color: $textRed;
    }
  }

  &__nurAlbee {
    font-weight: bold;
  }

  &__button {
    border: 0;
    background: transparent;
    height: 28px;
    width: 28px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    outline: none;
    display: block;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover{
      svg {
        fill: #385F9B;
      }
    }
  }

  &__not-found {
    padding: 15px;
    color: #4d4d4d;
    font-size: 18px;
    text-align: center;
  }
}

.modal {

  &-email {
    width: 320px;
  }

  &__header {
    background-color: $blue;
    display: flex;
    justify-content: space-between;
    margin: -20px -20px 20px -20px;
    padding: 10px;
    color: $white;

    @media (max-width: $mediaMobile) {
      padding: 7px 10px;
    }
  }

  &__info {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-grow: 3;
    padding-bottom: 20px;
    color: $textDarkGray;

    &--center {
      display: flex;
      flex-direction: column;
      align-self: center;

      @media (max-width: 380px) {
        font-size: 14px;
      }
    }

    &-row {
      width: 100%;
      display: flex;
    }

    &-left {
      margin-right: 5px;
    }

    > .modal__info-left { // fix for safari
      width: 100%;
    }

    &-day {
      width: 140px;
      flex-shrink: 0;

      &--small {
        width: 42px;

        &-text {
          width: calc(100% - 42px);
        }
      }

      &-text {
        width: calc(100% - 140px);
      }
    }
  }

  &__product-list {
    width: calc(100% - 30px);
    margin-left: 30px;
    margin-top: 15px;

    li {
      padding: 10px;
    }
  }

  &__button {
    display: inline-block;
    padding: 4px 20px;
    position: relative;
    top: -1px;
    background-color: $blue;
    font-size: 22px;
    border-radius: 10px;
    border: 1px solid $borderDefault;
    color: $white;
    text-decoration: none;
    cursor: pointer;
    -webkit-appearance: none !important;
  }

  &__copy {
    position: absolute;
    right: 17px;
    top: 137px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: 0;
    border: 0;
    text-indent: -999px;
    background-image: url(/sheet.svg);
    background-size: 90% 90%;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: $mediaMobile) {
      top: 125px;
    }
  }

  &__nav {
    display: flex;
    margin: -20px -20px 20px -20px;
    background: $bgSecondary;

    @media (max-width: $mediaMobile) {
      margin: -20px -20px 10px -20px;
    }

    &--bottom {
      @media (max-width: $mediaMobile) {
        position: absolute;
        bottom: 0;
        width: calc(100% + 40px);
        margin: 0;
        margin-left: -20px;
        margin-bottom: -20px;
        background: 0;
        border-top: 1px solid $borderDefault;
        padding: 5px;
        min-width: 308px;
      }
    }

    &-button {
      width: 50%;
      border: 0;
      cursor: pointer;
      padding: 10px;

      @media (max-width: $mediaMobile) {
        padding: 7px;
      }

      &--top {
        background-color: #dddddd;
      }

      &--bottom {
        @media (max-width: $mediaMobile) {
          color: $textDarkGray;
          text-align: center;
          overflow: hidden;
          padding: 0;

          &-text {
            display: flex;
            align-items: center;
            margin-left: -10px;
            padding: 7px;
          }
        }
      }

      &--borderRight {
        border-right: 1px solid $borderDefault;
      }

      &--active {
        background: $white;
        border-bottom: 2px solid $blue;
      }
    }
  }

  &__mobileButton {
    position: relative;
    color: $textDarkGray;
    text-decoration: none;
    font-size: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    -webkit-appearance: none !important;
  }

  &__icon {
    margin-right: 5px;
    height: 30px;
    width: 30px;
    color: $borderDefault;

    &.companies__button {

      &--route {
        svg {
          width: 104%;
          height: 104%;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__img {
    border: 0;
    padding: 0;
    background: transparent;
    height: 28px;
    width: 28px;
    margin: 0px 10px 12px 0;
    display: block;

    svg {
      width: 100%;
      height: 100%;
    }

    &--none {
      background: 0;
    }

  }


  &__title {
    margin: 5px 0;
    width: 100%;
    text-align: center;
    font-size: 22px;

    @media (max-width: $mediaMobile) {
      font-size: 18px;
    }
  }

  &__subtitle {
    margin: 5px 0;
    width: 100%;
    text-align: left;
    font-size: 18px;
  }

  &__item {
    width: 100%;

    &--teilen {
      padding: 0 20px 10px;
      box-sizing: border-box;
      position: relative;

      .modal__copy {
        right: 20px;
        top: -2px;

        @media (max-width: $mediaMobile) {
          top: -7px;
        }
      }
    }

    &--mb {
      margin-bottom: 20px;
    }
    &--center {
      text-align: center;
    }
    &--button {
      padding-bottom: 10px;
      margin: 0 30px 25px;
      width: auto;
      border-bottom: 1px solid $bgSecondary;
    }
  }

  &__text {
    min-height: 16px;
    margin: 0 0 10px 0;
    line-height: 20px;
    display: flex;
    max-width: 555px;

    @media (max-width: $mediaMobile) {
      margin: 0 0 5px 0;
    }

    &--light {
      color: $textLightGray;
    }

    &--bold {
      font-weight: bold;
      width: 135px;
    }
  }

  &__link {
    color: $textDarkGray;
    word-break: break-all;
    text-decoration: none;
    border-bottom: 1px solid $bgSecondary;
    line-height: 22px;
  }

  &__close {
    background: 0;
    border: 0;
    color: $white;
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 10px;

    &::before, &::after {
      position: absolute;
      content: ' ';
      height: 20px;
      width: 2px;
      top: 0px;
      background-color: $white;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

}
