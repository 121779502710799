@import "_vars.scss";
@import "normalize.css";

body {
    font-family: $fontFamily;
}

.no-pointer-events {
    pointer-events: none !important;
}

.search-icon {
    z-index: 9999 !important;
}

:focus {
    outline: none;
}