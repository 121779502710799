@import "../../_vars.scss";

.loader {

  &__loading {
    z-index: 1000;
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: $borderRadiusList;
  }

  &__loader-item {
    position: relative;
    width: 60px;
    height: 60px;

    div {
        position: absolute;
        width: 54px;
        height: 54px;
        margin: 6px;
        border: 6px solid #385f9b;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #385f9b transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.4s;
        }

        &:nth-child(2) {
            animation-delay: -0.25s;
        }

        &:nth-child(3) {
            animation-delay: -0.1s;
        }
    }
  }

}