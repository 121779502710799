@mixin autocompleteSearch {
    &__results {
        position: absolute;
        top: 100%;
        margin-top: 5px;
        display: flex;
        max-width: $searchWidth;
        max-height: 350px;
        width: 100%;
        z-index: 10;
        border: 1px solid $borderDefault;
        background: $white;
        border-radius: $borderRadiusList;

        &--no-results {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            color: #4d4d4d;
        }

        &--list {
            width: 100%;
            min-height: 62px;
            overflow-y: auto;

            &::-webkit-scrollbar-button {
                height: 0;
            }
        }

        &--item {
            width: 100%;

            &-button {
                @include resetBtnStyles();
                text-align: left;
                width: 100%;
                padding: 10px;

                &:focus {
                    outline: none;
                }

                @media (max-width: $mediaMobile) {
                    padding: 5px;
                }
            }

            &-title {
                margin-bottom: 5px;
                font-weight: bold;
                font-size: 18px;
                color: #333333;

                @media (max-width: $mediaMobile) {
                    font-size: 16px;
                }

                @media (max-height: 700px) and (max-width: 550px) {
                    margin-bottom: 0;
                }
            }

            &-address {
                @include textOverflow();
                text-overflow: ellipsis;
                font-size: 15px;

                @media (max-width: $mediaMobile) {
                    font-size: 14px;
                }
            }

            &:nth-child(2n-1) {
                background-color: #f3f3f3;
            }

            &:first-child {
                border-top-right-radius: $borderRadiusList;
                border-top-left-radius: $borderRadiusList;
            }

            &:last-of-type {
                border-bottom-left-radius: $borderRadiusList;
                border-bottom-right-radius: $borderRadiusList;
            }

            &:hover {
                background-color: #e0e0e0;
            }
        }
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}