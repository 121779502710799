@import "../../_vars.scss";

.companies {
  width: 400px;
  height: 100%;
  margin-right: 0;
  background-color: $bgCompany;

  @media (max-width: $mediaMobile) {
    position: absolute !important;
    top: $headerMobile;
    left: 0;
    max-width: 100%;
    z-index: 1100;
    background-color: transparentize($bgCompany, 0.15);
    height: calc(100vh - #{$headerFooterMobile}) !important;

    &--hide {
      display: none;
    }
  }

  @media (max-width: $mediaXS) {
    top: $headerMobileXS;
    height: calc(100vh - #{$headerFooterMobileXS}) !important;
  }

  >div>div { //no another access to react-window list
    overflow-y: scroll !important;
    width: 395px !important;

    @media (max-width: $mediaMobile) {
      max-width: calc(100vw - 5px) !important;
    }
  }
}

::-webkit-scrollbar-button {
  background-repeat: no-repeat;
  width: 0px;
  height: 30px
}

::-webkit-scrollbar-track {
  background-color: $scrollBackground;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: $borderDefault;
  box-shadow: 0px 1px 1px #fff inset;
}

::-webkit-resizer {
  background-repeat: no-repeat;
  width: 7px;
  height: 0px
}

::-webkit-scrollbar{
  width: 8px;
}