@import "../../_vars.scss";

.header {
  width: 100%;
  background-color: $bg;
  padding: 10px 20px 20px;
  border-bottom: 10px solid $borderDefault;

  @media (max-width: $mediaMobile) {
    position: absolute;
    top: 0;
    z-index: 1200;
    padding: 5px;
    height: auto !important;
    border-bottom: 0;
    background-color: $blue;
  }

  &__logo {

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media (max-width: $mediaMobile) {
      display: none;
    }

    a {
      height: 48px;
      display: block;

      img {
        height: 100%;
      }
    }

  }

  &__title {
    font-size: 48px;

    @media (max-width: $mediaMobile) {
      font-size: 24px;
      margin: 5px 0 10px;
      color: $white;
      text-align: center;
    }
  }
}

.locate {
  position: fixed;
  bottom: 65px;
  right: 30px;
  z-index: 800;
  width: 50px;
  height: 50px;
  text-indent: -999px;
  border: 1px solid $textGray;
  border-radius: 50%;
  background: $white;
  background-image: url(/locate.png);
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  @media (max-width: $mediaMobile) {
    bottom: 50px;
    right: 20px;
  }
}