@import "../../_vars.scss";
@import "../AutocompleteSearch/autocompleteSearch";

$searchWidth: 370px;
$searchInputHeight: 37px;
$searchInputHeightMobile: 30px;

.search {
  position: relative;

  &__input {
    width: 100%;
    max-width: $searchWidth;
    height: $searchInputHeight;
    padding: 5px 0 5px 40px;
    border: 1px solid $borderDefault;
    font-size: 22px;

    @media (max-width: $mediaMobile) {
      max-width: unset;
      height: $searchInputHeightMobile;
      padding: 5px 0 5px 30px;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 16px;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    height: $searchInputHeight; // must be same as height search_input
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;

    @media (max-width: $mediaMobile) {
      height: $searchInputHeightMobile;
      width: 32px;
    }
  }

  &__button {
    padding: 4px 20px;
    position: relative;
    top: -1px;
    background-color: $blue;
    font-size: 22px;
    border-radius: 10px;
    border: 1px solid $borderDefault;
    margin-left: 20px;
    color: $white;
    box-shadow: $boxShadow;
    cursor: pointer;

    @media (max-width: $mediaMobile) {
      display: none;
    }
  }

  &__clear {
    position: absolute;
    left: 337px;
    top: 1px;
    width: 32px;
    height: 35px;
    cursor: pointer;
    background: 0;
    border: 0;
    text-indent: -999px;
    background-image: url(/cross.svg);
    background-size: 90% 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $white;

    @media (max-width: $mediaMobile) {
      left: unset;
      right: 1px;
      height: 28px;
      background-size: 90%;
    }

    + .search__input {
      padding-right: 33px;
    }
  }

  @include autocompleteSearch();
}