@import "../../_vars.scss";

.cookie {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  font-family: Arial, Helvetica, sans-serif;

  &__container {
    position: fixed;
    left: calc(50% - 160px);
    width: 280px;
    background: $blue;
    color: $white;
    text-align: center;
    padding: 20px;
    letter-spacing: 1px;
  }

  &__item {
    font-size: 12px;
    margin: 20px 0;
    line-height: 18px;

    &--alt {
      text-align: left;
      margin: 10px 0;
    }
  }

  &__header {
    font-size: 18px;
    margin: 0 0 20px 0;
    font-weight: 400;
  }

  &__subheader {
    font-size: 14px;
    margin: 0 0 20px 0;
    font-weight: 400;
  }

  &__link {
    border: 0;
    background: 0;
    color: $white;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    letter-spacing: 1px;

    &:visited {
      color: $white;
    }
  }

  &__button {
    background: $white;
    color: $textGray;
    border: 1px solid $white;
    width: 100%;
    cursor: pointer;
    padding: 8px;
    text-align: left;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;

    &:hover {
      color: $white;
    }

    &--green {
      border-bottom: 1px solid $bgCompany;

      &:hover {
        background: $green;
      }
    }

    &--red {
      &:hover {
        background: $red;
      }
    }
  }

  &--smallText {
    font-size: 10px;
    line-height: 14px;
  }
}